@font-face {
  font-family: "Aktiv";
  src: url("/fonts/aktiv-regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Aktiv";
  src: url("/fonts/aktiv-medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Aktiv";
  src: url("/fonts/aktiv-bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

strong {
  font-weight: inherit;
}

html {
  font-size: 16px;
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (max-width: 400px) {
  html {
    font-size: 13px;
  }
}

img {
  max-width: 100%;
}

body {
  margin: 0;
  font-family: "Aktiv";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
}

.page {
  position: absolute;
  left: 0;
  right: 0;
}

.page-enter {
  opacity: 0;
}

.page-enter-active {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  /* transition-delay: 1000ms; */
  /* transition-delay: 0.3s; */
}

.page-exit {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.page-exit-active {
  opacity: 0;
}

.fadeInUp {
  opacity: 0;
}
